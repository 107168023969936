export const useAccessData = () => {
  const HEADER = {
    title: "Access, coverage, and Reimbursement",
  }

  const TOPIC = {
    header: "Support to help patients access Palforzia",
    topic:
      "Aimmune is committed to making PALFORZIA accessible by providing financial options to eligible patients. PALFORZIA Pathway supports patients and caregivers with useful resources and assistance.",
  }

  const CARD_A = {
    header: "Available resources with PALFORZIA Pathway enrollment:",
    cards: [
      {
        header: "Support Program",
        topic: {
          title: "This program helps answer your patients' questions about",
        },
        topicList: [
          "Accessing PALFORZIA",
          "Insurance coverage",
          "Financial assistance options",
        ],
        footer: "Learn more about the PALFORZIA Pathway program.",
        cta: {
          label: "Download Brochure",
          showFileIcon: true,
          url: "/coverage_reimbursement.pdf",
          target: "_blank",
        },
      },
      {
        header: "Co-Pay Savings\nProgram",
        topic: {
          title:
            "Commercially insured patients who meet eligibility criteria may",
          footnotes: ["*"],
        },
        topicList: [
          "Receive help with out-of-pocket costs for PALFORZIA",
          "Pay as little as $20 per month, with a maximum benefit of $6,200 per calendar year",
        ],
        footer:
          "Encourage eligible patients to apply for the co-pay program online.",
        cta: {
          label: "View Program Website",
          showFileIcon: false,
          url: "http://www.PALFORZIACoPay.com",
          target: "_blank",
        },
      },
      {
        header: "Patient Assistance\nProgram (PAP)",
        topic: {
          title:
            "Uninsured patients or patients whose insurance does not cover PALFORZIA may",
          footnotes: ["†"],
        },
        topicList: [
          "Receive PALFORZIA  at no cost if they meet specific eligibility and financial criteria",
        ],
        footer:
          "Complete a Prescription and Enrollment Form for your eligible patients.",
        cta: {
          label: "Download Form",
          showFileIcon: true,
          url: "/enrollment.pdf",
          target: "_blank",
        },
      },
    ],
    footnotes: [
      "Terms and conditions apply. See PALFORZIACoPay.com for full terms and conditions.",
      "Terms and conditions apply. Call 1-844-PALFORZ for information about applying.",
    ],
  }

  const CARD_B = {
    header: "It's easy to enroll\nyour patients in\nPALFORZIA Pathway",
    topic:
      "Prescribers, healthcare settings, and patients must be enrolled in the PALFORZIA REMS Program before a patient can be enrolled in PALFORZIA Pathway.",
    cards: [
      {
        image: "Card1",
        alt: "Prescription and enrollment form icon",
        body: "DOWNLOAD a",
        cta: {
          label: "Prescription and\nEnrollment Form",
          url: "/enrollment.pdf",
          target: "_blank",
        },
      },
      {
        image: "Card2",
        alt: "Completed form icon",
        body:
          "COMPLETE the form, and make\nsure you get the patient's\ninformation and signature",
      },
      {
        image: "Card3",
        alt: "Phone icon",
        body: "FAX the completed form to\n1-844-708-0011",
      },
    ],
  }

  return {
    header: { ...HEADER },
    topic: { ...TOPIC },
    cardA: { ...CARD_A },
    cardB: { ...CARD_B },
  }
}
