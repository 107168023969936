import React from "react"
import { Container } from "react-bootstrap"

import { graphql } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import { useResourceCenterMenuData } from "hooks/contentful/useResourceCenterMenuData"
import { useAccessData } from "hooks/contentful/pages/useAccessData"
import { FOOTNOTE_SYMBOLS } from "constants/Site"

import Layout from "components/Layout"
import SEO from "components/Seo"
import ExternalLink from "components/Modal/ExternalLink"
import PageTitle from "components/PageTitle/PageTitle"
import CaptionCTA from "components/CaptionCTA/CaptionCTA"

import BrandLogo from "../images/palforzia-pathway-logo.svg"
import Card1 from "../images/access-card-b-1.svg"
import Card2 from "../images/access-card-b-2.svg"
import Card3 from "../images/access-card-b-3.svg"
import ExternalLinkIcon from "assets/svg/external-link"
import DownloadIcon from "assets/svg/download-icon"

import "../styles/main.scss"
import styles from "../styles/pages/access.module.scss"

const IMAGES = {
  Card1: Card1,
  Card2: Card2,
  Card3: Card3,
}

const Card = ({ header, topic, topicList, footer, cta }) => {
  return (
    <li className={styles.card}>
      <h3 className={styles.cardHeader}>{header}</h3>
      <p className={styles.cardBody}>
        {topic.title}
        {topic.footnotes?.length > 0 && (
          <sup className={styles.footnote}>{topic.footnotes.join("")}</sup>
        )}
        :
      </p>
      <ul className={styles.cardTopicList}>
        {topicList.map((topic) => (
          <li className={styles.cardTopic} key={topic}>
            {topic}
          </li>
        ))}
      </ul>
      <div className={styles.cardFooter}>
        <h4>{footer}</h4>
        {cta.showFileIcon ? (
          <a className={styles.cta} href={cta.url} target={cta.target}>
            {cta.label}
            <DownloadIcon classes={`${styles.icon} ${styles.downloadIcon}`} />
          </a>
        ) : (
          <ExternalLink className={styles.cta} url={cta.url}>
            {cta.label}
            <ExternalLinkIcon classes={styles.icon} />
          </ExternalLink>
        )}
      </div>
    </li>
  )
}

const Access = ({ data }) => {
  const captionCTA = useResourceCenterMenuData()
  const { header, topic, cardA, cardB } = useAccessData()

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout>
        <SEO title={data.page.title} description={data.page.description} />
        <main className={styles.access}>
          <PageTitle title={header.title}></PageTitle>
          <Container fluid as={"section"} className={styles.accessSection}>
            <Container>
              <div className={styles.cardsHeader}>
                <div className={styles.topicContainer}>
                  <h2 className={styles.header}>{topic.header}</h2>
                  <p className={styles.body}>{topic.topic}</p>
                </div>
                <div className={styles.logoContainer}>
                  <img
                    className={styles.logo}
                    alt="Palforzia Pathway Logo"
                    src={BrandLogo}
                  />
                </div>
              </div>
              <div className={styles.cardA}>
                <h2 className={styles.header}>{cardA.header}</h2>
                <ul className={styles.cardsList}>
                  {cardA.cards.map((card, index) => {
                    return <Card {...card} key={index} />
                  })}
                </ul>
                <ol className={styles.footnotesList}>
                  <li className={styles.footnoteRef}>
                    <sup className={styles.symbol}>{FOOTNOTE_SYMBOLS[0]}</sup>
                    Terms and conditions apply. See{" "}
                    <ExternalLink url={"https://www.palforziacopay.com"}>
                      PALFORZIACoPay.com
                    </ExternalLink>{" "}
                    for full terms and conditions.
                  </li>
                  <li className={styles.footnoteRef}>
                    <sup className={styles.symbol}>{FOOTNOTE_SYMBOLS[1]}</sup>
                    Terms and conditions apply. Call 1-844-PALFORZ for
                    information about applying.
                  </li>
                </ol>
              </div>
            </Container>
          </Container>
          <Container fluid as={"section"} className={styles.accessSectionB}>
            <Container>
              <div className={styles.cardB}>
                <h3 className={styles.cardBHeader}>{cardB.header}</h3>
                <ul className={styles.ctaCards}>
                  {cardB.cards.map((card, index) => {
                    return (
                      <li key={index} className={styles.ctaCard}>
                        <img
                          className={styles.logo}
                          alt={card.alt}
                          src={IMAGES[card.image]}
                        />
                        <p className={styles.ctaBody}>
                          {card.body}
                          {card.cta && (
                            <a href={card.cta.url} target={card.cta.target}>
                              {card.cta.label}
                            </a>
                          )}
                        </p>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </Container>
          </Container>
          <CaptionCTA {...captionCTA} />
        </main>
      </Layout>
    </StateProvider>
  )
}

export default Access

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "Access and Reimbursement" }) {
      label
      url
      title
      description
    }
  }
`
