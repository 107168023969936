import { useStaticQuery, graphql } from "gatsby"

import { HCP_TOPIC, NAVIGATION_ELEMENT } from "constants/ContentfulContentTypes"

export const useResourceCenterMenuData = () => {
  const { seeResults } = useStaticQuery(graphql`
    query {
      seeResults: contentfulHcpMenuAssembly(label: { eq: "Resource Center" }) {
        content {
          ...TopicFragment
          ...NavigationElementFragment
        }
      }
    }
  `)

  const header = seeResults.content.find(
    (item) => item.__typename === HCP_TOPIC
  )

  const navigationElement = seeResults.content.find(
    (item) => item.__typename === NAVIGATION_ELEMENT
  )

  return {
    header: {
      title: header.content,
    },
    navigationElement: {
      label: navigationElement.label,
      action: {
        url: navigationElement.action.url,
      },
      showIcon: navigationElement.showIcon,
    },
  }
}
